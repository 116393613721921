
import { defineComponent, ref, onMounted } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { useRouter } from "vue-router";
import {mask} from 'vue-the-mask'
import {slab} from "@/core/data/genericData";
import {active} from "@/core/data/genericData";
export default defineComponent({
  name: "edit-product-hsn-modal",
  directives: {mask}, 
  props: {
    data: {
        type: Object
    }
  },

  setup(props) {
    const formRef = ref<null | HTMLFormElement>(null);
    const editProductHsnModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const store = useStore();
    const router = useRouter(); 
    var formData = ref({})
    const slab_list_data = slab;
    const active_list_data = active;
    const setFormData = async (data) => {    
      try 
      {
        var values = { "hsn_code": data.ids, "page": 1, "records_per_page": 1 }
        await store.dispatch(Actions.CUST_HSN_LIST, values).then(({ data }) => {       
        formData.value = {
          hsn : Number(data.hsn_code),
          heading: Number(data.hsn_heading),
          description: data.hsn_description,
          chapter: Number(data.hsn_chapter),
          slab0 : data.tax_slab["0"],
          slab5 : data.tax_slab["5"],
          slab12 : data.tax_slab["12"],
          slab18 : data.tax_slab["18"],
          slab28 : data.tax_slab["28"],
          active : data.active,
          };
        })
        .catch(({ response }) => {
          console.log(response);
        });
        
      } catch (e) {
        console.log(e);
      }  
    }

    const rules = ref({
      hsn: [
        // {
        //   required: true,
        //   message: "Enter 8-digit Hsn",
        //   min: 8,
        //   max: 8,        
        // },        
      ],    
      chapter: [{
          required: true,
          message: 'Please Enter Chapter',
        },
        {
          required: true,
          message: "Enter 2-digit Chapter",
          trigger: "click",  
          pattern: /[0-9]/,
          min: 2,
          max: 2,     
        },       
      ],    
      heading: [{
          required: true,
          message: 'Please enter Heading',
        },
        {
          required: true,
          message: "Enter 4-digit Heading",
          trigger: "click",  
          pattern: /[0-9]/,
          min: 4,
          max: 4,        
        },        
      ],  
      description: [
        {
          required: true,
          message: "Please Enter Description",
          trigger: "change",      
        },        
      ],    
      slab0: [
        {
          required: true,
          message: "Please select",
          trigger: "change",  
                
        },        
      ],    
      slab5: [
        {
          required: true,
          message: "Please select",
          trigger: "change",  
                
        },        
      ],    
      slab12: [
        {
          required: true,
          message: "Please select",
          trigger: "change",  
                
        },        
      ],    
      slab18: [
        {
          required: true,
          message: "Please select",
          trigger: "change",  
                
        },        
      ],    
      slab28: [
        {
          required: true,
          message: "Please select",
          trigger: "change",  
                
        },        
      ],      
    });

    const setHsnData = async (data) => {
      const taxslab = {
        "0" : Number(data.slab0),
        "5" : Number(data.slab5),
        "12" : Number(data.slab12),
        "18" : Number(data.slab18),
        "28" : Number(data.slab28),
      }
      
      const db_data = {
        "hsn_code": Number(data.hsn),
        "hsn_chapter": Number(data.chapter),
        "hsn_heading": Number(data.heading),
        "hsn_description": data.description,
        "tax_slab": taxslab,
        "cess_details": {},
        "active": data.active
        }

        await store.dispatch(Actions.CUST_UPDATE_HSN, db_data).then(({ is_error }) => {
         
          if (is_error==false)
          {
            setTimeout(() => {
              loading.value = false;
              Swal.fire({
                text: "Hsn has been successfully Updated.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                hideModal(editProductHsnModalRef.value);  
                router.go(0)           
              });
            }, 2000)          
          } 
          else 
          {
            loading.value = false;
            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            }) 
            return false;
          }
      
      })
      .catch(({ response }) => {
        console.log(response);        
      });

    }
   
    const submit = async () => {

      loading.value = true;
      if (!formRef.value) 
      {
        loading.value = false;
        return;
      }

      formRef.value.validate (async (valid) => {

        if (valid) 
        {
          await setHsnData(formData.value);
        } 
        else 
        {
          loading.value = false;
          return false;
        }
      });    
    };

    
    onMounted( async () => {
      await setFormData(props.data);
    });
    return {      
      formData,      
      rules,
      submit,
      formRef,
      loading,
      editProductHsnModalRef,
      slab_list_data,
      active_list_data
    };
  },
});
